import {AureliaReactReportWrapper} from "$pages/reports-react/AureliaReactReportWrapper";
import IdaxLifecycleReport from "$pages/reports-react/idax-lifecycle-report/idax-lifecycle-report";

class IdaxLifecycleReportReactWrapper extends AureliaReactReportWrapper {
  constructor() {
    super(IdaxLifecycleReport);
  }
}

export default IdaxLifecycleReportReactWrapper
