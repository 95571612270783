import { useCaseInsensitiveTranslation } from "$lib/hooks/case-insensitive-translation"
import { FC, HTMLAttributes } from "react"
import { SortObject, useGenericReport } from "../generic-report"
import { IdaxLifecycleReportDocument, IdaxLifecycleReportDownloadDocument, IdaxLifecycleReportDownloadQuery, IdaxLifecycleReportQuery, IdaxLifecycleReportQueryVariables, Reports, SortDirection } from "$typings/graphql-codegen"
import { ColumnDefinition } from "../column-definition"
import { ColumnWidth } from "../ColumnWidth"
import React from "react"


enum ColumnKey {
    Buid = 'buid',
    ControllerType = "controllerType",
    Shipped = 'shipped',
    Active = 'active',
    Activated = 'activated',
    Decommissioned = 'decommissioned',
    Idle = 'idle',
  }

type ReportResult = IdaxLifecycleReportQuery
type ReportVariables = IdaxLifecycleReportQueryVariables
type ReportData = IdaxLifecycleReportQuery["reports"]["idaLifecycle"]["data"][0]
type ReportDownloadResult = IdaxLifecycleReportDownloadQuery

  const IdaxLifecycleReport: FC<HTMLAttributes<HTMLElement>> = () => {
    const [t] = useCaseInsensitiveTranslation()
    const Title: string = t('ui_reports_ida_lifecycle_title')
    const Filename: string = 'idax-lifecycle-report'
    
    const DefaultSortSettings: SortObject =  {
      sortColumnKey: ColumnKey.Buid,
      sortDirection: SortDirection.Desc
    }
    
    const ColumnDefinitions: ColumnDefinition<ReportData>[] = [
      new ColumnDefinition<ReportData>(
        t('ui_reports_ida_lifecycle_table_header_buid'),
        (row: ReportData) => String(row[ColumnKey.Buid]),
        ColumnKey.Buid,
        ColumnWidth.Common.Buid
      ),
      new ColumnDefinition<ReportData>(
        t('ui_reports_ida_lifecycle_table_header_controllertype'),
        (row: ReportData) => String(row[ColumnKey.ControllerType]),
        ColumnKey.ControllerType,
        ColumnWidth.Generic.Short
      ),
      new ColumnDefinition<ReportData>(
        t('ui_reports_ida_lifecycle_table_header_shipped'),
        (row: ReportData) => String(row[ColumnKey.Shipped]),
        ColumnKey.Shipped,
        ColumnWidth.Generic.Short
      ),
      new ColumnDefinition<ReportData>(
        t('ui_reports_ida_lifecycle_table_header_active'),
        (row: ReportData) => String(row[ColumnKey.Active]),
        ColumnKey.Active,
        ColumnWidth.Generic.Short
      ),
      new ColumnDefinition<ReportData>(
        t('ui_reports_ida_lifecycle_table_header_activated'),
        (row: ReportData) => String(row[ColumnKey.Activated]),
        ColumnKey.Activated,
        ColumnWidth.Generic.Short
      ),
      new ColumnDefinition<ReportData>(
        t('ui_reports_ida_lifecycle_table_header_decommissioned'),
        (row: ReportData) => String(row[ColumnKey.Decommissioned]),
        ColumnKey.Decommissioned,
        ColumnWidth.Generic.Short
      ),
      new ColumnDefinition<ReportData>(
        t('ui_reports_ida_lifecycle_table_header_idle'),
        (row: ReportData) => String(row[ColumnKey.Idle]),
        ColumnKey.Idle,
        ColumnWidth.Generic.Short
      ),
    ]
    
    const { GenericReport } = useGenericReport<
      ReportResult,
      ReportVariables,
      ReportData,
      ReportDownloadResult
    >()
    
    function createTitle(): JSX.Element {
        return <div>{Title}</div>
      }
    
    function dataSelector(result: ReportResult): ReportData[] {
      return result?.reports?.idaLifecycle?.data as ReportData[] ?? []
    }
    
    function dataCounter(result: ReportResult): number {
      return dataSelector(result).length
    }
    
    function downloadUrlSelector(result: ReportDownloadResult): string {
      return result?.reports?.idaLifecycle?.blobUrl ?? ''
    }
    
    return <GenericReport
      titleCallback={createTitle}
      reportEnum={Reports.IdaLifecycle}
      mainQueryDocument={IdaxLifecycleReportDocument}
      downloadQueryDocument={IdaxLifecycleReportDownloadDocument}
      columnDefinitions={ColumnDefinitions}
      dataSelector={dataSelector}
      dataCounter={dataCounter}
      defaultSortSettings={DefaultSortSettings}
      downloadUrlSelector={downloadUrlSelector}
      downloadFilename={Filename} />
  }

  export default IdaxLifecycleReport
